import React from 'react';
import styled from 'styled-components';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';

import {ContainerLg} from '../components';
import {breakpointsMediaQueries, COLORS} from '../../style/my-theme';

const StyledSpeakersSection = styled.section.attrs({
  className: 'speakers-overview-section'
})`
  margin: 4rem 0;

  .container-lg {
    h1 {
      color: ${COLORS.blueLight};
      font-size: 2rem;
      text-align: center;
      text-transform: uppercase;
    }

    .speakers-wrapper {
      display: grid;
      grid-column-gap: 5%;
      grid-row-gap: 2rem;
      grid-template-columns: 30% 30% 30%;

      ${breakpointsMediaQueries.md} {
        grid-template-columns: auto;
      }

      .speaker-info {
        .gatsby-image-wrapper {
          width: 100%;
        }
        .speaker-title {
          display: grid;
          padding: 1rem;
          background: #100f21;
          color: #fff;
          text-align: center;

          .name {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
`;

const SpeakerTile = ({speaker}: {speaker: any}) => {
  const image = getImage(speaker.node.frontmatter.imageSrc);
  return (
    <div className="speaker-info">
      {image && <GatsbyImage image={image} alt={speaker.node.frontmatter.fullName} />}
      <div className="speaker-title">
        <span className="name">{speaker.node.frontmatter.fullName}</span>
        <span>
          {speaker.node.frontmatter.title} @ {speaker.node.frontmatter.company}
        </span>
      </div>
    </div>
  );
};

const SpeakersSection = ({speakers}: {speakers: any}) => (
  <StyledSpeakersSection>
    <ContainerLg>
      <h1>Speakers</h1>
      <div className="speakers-wrapper">
        {speakers.map((speaker: any) => {
          return (
            <Link key={speaker.node.id} to={`/speakers/${speaker.node.slug}`}>
              <SpeakerTile speaker={speaker} />
            </Link>
          );
        })}
      </div>
    </ContainerLg>
  </StyledSpeakersSection>
);

export default SpeakersSection;
