import React from 'react';
import styled from 'styled-components';

import {COLORS} from '../../style/my-theme';
import {ContainerLg} from '../components';

const StyledEmbededVideoSection = styled.section.attrs({
  className: 'embeded-video-section'
})`
  margin: 4rem 0;

  .container-lg {
    h1 {
      color: ${COLORS.blueLight};
      font-size: 2rem;
      text-align: center;
      text-transform: uppercase;
    }
  }
`;

const EmbededVideoSection = ({url, title}: {url: string; title: string}) => (
  <StyledEmbededVideoSection>
    <ContainerLg>
      <h1>{title}</h1>
      <iframe
        width="100%"
        height="400"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </ContainerLg>
  </StyledEmbededVideoSection>
);

export default EmbededVideoSection;
