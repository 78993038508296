import React from 'react';
import styled from 'styled-components';

import {ContainerLg} from '../components';
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';
import {graphql, useStaticQuery} from 'gatsby';
import {breakpointsMediaQueries, COLORS} from '../../style/my-theme';
import {SponsorInfo} from '../content';

const StyledSponsorsSection = styled.section.attrs({
  className: 'sponsors-overview-section'
})`
  margin: 4rem 0;

  .container-lg {
    .section-description {
      line-height: 2rem;
      text-align: center;
      margin-bottom: 4rem;
      border-bottom: 2px solid ${COLORS.primary};

      h1 {
        color: ${COLORS.blueLight};
        font-size: 2rem;
      }
      h2 {
        color: ${COLORS.primary};
        font-size: 1.6rem;
        a {
          color: ${COLORS.blueLight};
        }
      }
    }
    .logo-wrapper {
      filter: grayscale(100%);
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
      text-align: center;
      grid-column-gap: 5%;
      grid-row-gap: 2rem;
      ${breakpointsMediaQueries.md} {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      }

      a {
        display: table;
        height: 100%;
        .gatsby-image-wrapper {
          display: table-cell;
          vertical-align: middle;
          text-align: center;

          img {
            ${breakpointsMediaQueries.md} {
              width: 80px;
            }
          }
        }
      }
    }
  }
`;

const SponsorTile = ({sponsor}: {sponsor: SponsorInfo}) => {
  const imagesQuery = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/sponsors-old/"}}) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 160
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);
  const imageNode = imagesQuery.allFile.nodes.find(
    (element: {relativePath: string}) => element.relativePath === sponsor.imageUrl
  );
  const image = getImage(imageNode);
  return image ? (
    <GatsbyImage image={image} alt={sponsor.websiteUrl} objectFit={'contain'} />
  ) : null;
};

const SponsorsSection = ({sponsors}: {sponsors: SponsorInfo[]}) => {
  return (
    <StyledSponsorsSection>
      <ContainerLg>
        <div className="section-description">
          <h1>COMPANIES THAT SUPPORTED US IN THE PAST</h1>
          <h2>
            WANT TO JOIN AS A SPONSOR? <br /> <a href="mailto:contact@armada-js.com">CONTACT US!</a>
          </h2>
        </div>
        <div className="logo-wrapper">
          {sponsors.map((sponsor: SponsorInfo, index: number) => {
            return (
              <a key={index} href={sponsor.websiteUrl}>
                <SponsorTile sponsor={sponsor} />
              </a>
            );
          })}
        </div>
      </ContainerLg>
    </StyledSponsorsSection>
  );
};

export default SponsorsSection;
